export const LOGIN = 'login';
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_FAILURE = 'login_failure';

export const LOGOUT = 'logout';
export const LOGOUT_SUCCESS = 'logout_success';
export const LOGOUT_FAILURE = 'logout_failure';

export const FETCH_LOGGED_USER = 'fetch_logged_user';
export const FETCH_LOGGED_USER_SUCCESS = 'logged_user_success';
export const FETCH_LOGGED_USER_FAILURE = 'logged_user_failure';

export const FETCH_PEDIDOS = 'fetch_pedidos';
export const FETCH_PEDIDOS_SUCCESS = 'fetch_pedidos_success';
export const FETCH_PEDIDOS_FAILURE = 'fetch_pedidos_failure';

export const FETCH_SETTINGS_DATA = 'fetch_settings_data';
export const FETCH_SETTINGS_SUCCESS = 'fetch_settings_success';
export const FETCH_SETTINGS_FAILURE = 'fetch_settings_failure';

export const FETCH_PESSOA_DATA = 'fetch_pessoa_data';
export const FETCH_PESSOA_SUCCESS = 'fetch_pessoa_success';
export const FETCH_PESSOA_FAILURE = 'fetch_pessoa_failure';
export const SAVE_PESSOA_DATA = 'save_pessoa_data';
export const SAVE_PESSOA_SUCCESS = 'save_pessoa_success';
export const SAVE_PESSOA_FAILURE = 'save_pessoa_failure';
export const DELETE_PESSOA_DATA = 'delete_pessoa_data';
export const DELETE_PESSOA_SUCCESS = 'delete_pessoa_success';
export const DELETE_PESSOA_FAILURE = 'delete_pessoa_failure';

export const FETCH_PRODUTO_DATA = 'fetch_produto_data';
export const FETCH_PRODUTO_SUCCESS = 'fetch_produto_success';
export const FETCH_PRODUTO_FAILURE = 'fetch_produto_failure';
export const SAVE_PRODUTO_DATA = 'save_produto_data';
export const SAVE_PRODUTO_SUCCESS = 'save_produto_success';
export const SAVE_PRODUTO_FAILURE = 'save_produto_failure';
export const DELETE_PRODUTO_DATA = 'delete_produto_data';
export const DELETE_PRODUTO_SUCCESS = 'delete_produto_success';
export const DELETE_PRODUTO_FAILURE = 'delete_produto_failure';

export const FETCH_NFES_DATA = 'fetch_nfes_data';
export const FETCH_NFES_SUCCESS = 'fetch_nfes_success';
export const FETCH_NFES_FAILURE = 'fetch_nfes_failure';
export const FETCH_NFE_DATA = 'fetch_nfe_data';
export const FETCH_NFE_SUCCESS = 'fetch_nfe_success';
export const FETCH_NFE_FAILURE = 'fetch_nfe_failure';
export const SAVE_NFE_DATA = 'save_nfe_data';
export const SAVE_NFE_SUCCESS = 'save_nfe_success';
export const SAVE_NFE_FAILURE = 'save_nfe_failure';
export const DELETE_NFE_DATA = 'delete_nfe_data';
export const DELETE_NFE_SUCCESS = 'delete_nfe_success';
export const DELETE_NFE_FAILURE = 'delete_nfe_failure';

export const SEND_PING = 'send_ping';
export const FETCH_VERSION = 'fetch_version';

export const FETCH_BOLETO = 'fetch_boleto';
export const SAVE_BOLETO = 'save_boleto';
export const FETCH_BOLETOS = 'fetch_boletos';

export const FETCH_NFE = 'fetch_nfe';
export const SAVE_NFE = 'save_nfe';
export const FETCH_NFES = 'fetch_nfes';

export const FETCH_NATOPS = 'fetch_natops';

export const SEND_LABEL_DATA = 'send_label_data';
export const PROCESS_LABELS = 'process_labels';


export const FETCH_GRUPOS_CLIENTES_DATA = 'fetch_grupos_grupo_clientes_data';
export const FETCH_GRUPOS_CLIENTES_SUCCESS = 'fetch_grupos_grupo_clientes_success';
export const FETCH_GRUPOS_CLIENTES_FAILURE = 'fetch_grupos_grupo_clientes_failure';

export const FETCH_CLIENTES_DATA = 'fetch_clientes_data';
export const FETCH_CLIENTES_SUCCESS = 'fetch_clientes_success';
export const FETCH_CLIENTES_FAILURE = 'fetch_clientes_failure';

export const FETCH_CONTRATOS_DATA = 'fetch_contratos_data';
export const FETCH_CONTRATOS_SUCCESS = 'fetch_contratos_success';
export const FETCH_CONTRATOS_FAILURE = 'fetch_contratos_failure';

export const FETCH_CONTRATO_DATA = 'fetch_contrato_data';
export const FETCH_CONTRATO_SUCCESS = 'fetch_contrato_success';
export const FETCH_CONTRATO_FAILURE = 'fetch_contrato_failure';

export const SAVE_CONTRATO_DATA = 'save_contratos_data';
export const SAVE_CONTRATO_SUCCESS = 'save_contratos_success';
export const SAVE_CONTRATO_FAILURE = 'save_contratos_failure';

export const FETCH_RELATORIO_EMBALAGEM = 'fetch_relatorio_embalagem';
export const FETCH_RELATORIO_EMBALAGEM_SUCCESS = 'fetch_relatorio_embalagem_success';
export const FETCH_RELATORIO_EMBALAGEM_FAILURE = 'fetch_relatorio_embalagem_failure';