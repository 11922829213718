import { Field, reduxForm } from 'redux-form'
import { Outlet, Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { renderSelectField } from '../../../fragments/SelectField'
import { renderDateRangeSelect } from '../../../fragments/DateRangePicker'
import renderTextField from '../../../fragments/textField';
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'


let Filter = props => {
  const { fetchRelatorioEmbalagem, updatePedidos, fetchPedidos, fetchClientes, handleSubmit, grupos, clientes } = props
  
  const onSubmit = values => {
    
    fetchRelatorioEmbalagem(values)
  }

  return <div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>

        <Grid item xs={12} sm={4}>
          <Field
            id="turno"
            name="turno"
            component={renderSelectField}
            label="Turno:"
          >
            <MenuItem value='NOITE' >Noite - 1</MenuItem>
            <MenuItem value='DIA' >Dia - 2</MenuItem>
          </Field>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Field
            id="data"
            name="data"
            component={renderDateRangeSelect}
            label="Data:"
            required={true}
          />
        </Grid>

        <Grid container item >
          <Button variant="contained" color="primary" type="submit">
            Processar
          </Button>
        </Grid>

      </Grid>
    </form>

    
    <Outlet />
  </div>
}

Filter = reduxForm({
  form: 'reportFilterForm',
  initialValues: { turno: 'NOITE', data: new Date()}
})(Filter)

export default Filter