import axios from 'axios';
import {
    LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE,
    LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAILURE
} from './types';

export const checkCookie = cookie => dispatch => {
    dispatch({ type: LOGIN});
    if(cookie.JSESSIONIDSSO) {
        dispatch({ type: LOGIN_SUCCESS, payload: true});
    } else {
        dispatch({ type: LOGIN_FAILURE});
    }
}

export const login = data => async dispatch => {
    dispatch({ type: LOGIN});
    try {
        let res = await axios.post('/vaast-admin-web/auth', data);
        dispatch({ type: LOGIN_SUCCESS, payload: true});
    } catch (err) {
        const { status, statusText } = err.response
        dispatch({ type: LOGIN_FAILURE, error: { status, statusText } });
    }
}

export const logout = () => async dispatch => {
    dispatch({ type: LOGOUT });
    try {
        await axios.post(`/vaast-admin-web/auth/logout`);
        dispatch({ type: LOGOUT_SUCCESS});
    } catch (err) {
        dispatch({ type: LOGOUT_FAILURE, error: err });
    }
}
