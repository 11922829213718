import { Routes, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import Print from './Print'
import Default from './Default'
import { logout } from '../../actions'


const Index = props => {
    
    return <Routes>
          <Route path='/print/*' element={<Print />} />
          <Route path='*' element={<Default logout={props.logout} loggedUser={props.loggedUser} />} />
    </Routes>
}


function mapStateToProps({ loggedUser }) {
    return { loggedUser };
  }
  
export default connect(mapStateToProps, { logout })(Index)