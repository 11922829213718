import { FETCH_RELATORIO_EMBALAGEM, FETCH_RELATORIO_EMBALAGEM_SUCCESS, FETCH_RELATORIO_EMBALAGEM_FAILURE } from '../actions/types';

const initialState = {
  isLoading: false
}

export default function (state = initialState, action) {
  switch (action.type) {

    case FETCH_RELATORIO_EMBALAGEM:
      return {
        ...state,
        isLoading: true
      };

    case FETCH_RELATORIO_EMBALAGEM_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isLoading: false
      };

    case FETCH_RELATORIO_EMBALAGEM_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };

    default:
      return state;

  }
}