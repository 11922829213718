import * as React from 'react';
import { Routes, Route, Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import Home from '../home/Home';
import Profile from '../profile';
import Producao from '../producao';
import Pedido from '../pedido';
import Contrato from '../contrato';
import Faturamento from '../faturamento';
import NFeRelatorio from '../nfe/relatorio';
import NFeRelatorioDetalhado from '../nfe/relatorio/detalhado';

const drawerWidth = 240;

const menuItens = [
  {name: 'Início', url: '/', icon: <HomeIcon/>}, 
  {name: 'Produtos', url: '/produto', icon: <BusinessCenterIcon/>}, 
  {name: 'Pedidos', url: '/pedido/tool', icon: <ShoppingCartIcon/>}, 
  {name: 'Pedidos Relatório', url: '/pedido/relatorio', icon: <SummarizeIcon/>},
  {name: 'Contratos', url: '/contrato', icon: <AssignmentIcon/>},
  {name: 'Pedidos / Faturamento', url: '/faturamento', icon: <ReceiptIcon/>},
  {name: 'Pedidos / Relatório detalhado', url: '/pedido/relatorio/detalhado', icon: <SummarizeIcon/>}, 
  {name: 'NFe / Relatório', url: '/nfe/relatorio', icon: <ReceiptIcon/>},
  {name: 'NFe / Relatório detalhado', url: '/nfe/relatorio/detalhado', icon: <SummarizeIcon/>}
]

const managementMenuItens = [
  //{name: 'Produto', url: '/producao/produto', icon: <SummarizeIcon/>},
  //{name: 'Embalagem', url: '/producao/tipo_embalagem', icon: <SummarizeIcon/>},
  {name: 'Relatório Embalagem', url: '/producao/relatorio/embalagem', icon: <SummarizeIcon/>},

]

function ResponsiveDrawer(props) {
  const { window, loggedUser } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderManagementMenu = loggedUser => {

    /*

    var rolesArray = []
    if(loggedUser.payload) {
      const {roles} = loggedUser.payload.user;
       rolesArray = roles.map(i => {
        return i.role
      });
    }
    return rolesArray.includes('GESTOR') &&
    */
    return <>
    <List>
        {managementMenuItens.map((menu, index) => (
          <ListItem key={menu.name} disablePadding component={Link} to={menu.url}>
            <ListItemButton>
              <ListItemIcon>
                {menu.icon}
              </ListItemIcon>
              <ListItemText primary={menu.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
        </>
  }

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      {renderManagementMenu(loggedUser)}
      <List>
        {menuItens.map((menu, index) => (
          <ListItem key={menu.name} disablePadding component={Link} to={menu.url}>
            <ListItemButton>
              <ListItemIcon>
                {menu.icon}
              </ListItemIcon>
              <ListItemText primary={menu.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            SIG v2.0
          </Typography>

          <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/*<MenuItem onClick={handleClose} component={Link} to='/perfil' >Meu perfil</MenuItem>*/}
                <MenuItem onClick={ async () => await props.logout()}>Sair</MenuItem>
              </Menu>
            </div>

        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Routes>
               <Route path="/" element={<Home />}/>
              <Route path="/profile" element={<Profile />}/>
              <Route path="/pedido/*" element={<Pedido />}/>
              <Route path="/contrato/*" element={<Contrato />}/>
              <Route path="/producao/*" element={<Producao />}/>
              <Route path="/faturamento/*" element={<Faturamento />}/>
              <Route path="/nfe/relatorio/detalhado" element={<NFeRelatorioDetalhado />}/>
              <Route path="/nfe/relatorio/*" element={<NFeRelatorio />}/>
        </Routes>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
