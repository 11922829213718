
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
//import { ProductService } from './service/ProductService';

export default function ExportDemo(props) {
  const [products, setProducts] = useState([]);
  const dt = useRef(null);
  
  const {relatorio} = props

    const cols = [
        { field: 'categoria', header: 'Categoria' },
        { field: 'descricao', header: 'Descrição' },
        { field: 'quantidade', header: 'Quantidade' }
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    useEffect(() => {
        setProducts(relatorio);
    }, []);

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, products);
                doc.save('products.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(products);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'products');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
          <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
        </div>
    );


    const headerTemplate = (data) => {
      return (
          <div className="flex align-items-center gap-2">
              <span className="font-bold"><strong>{data.categoria}</strong></span>
          </div>
      );
  };

    return (
        <div className="card">
            <Tooltip target=".export-buttons>button" position="bottom" />

            <DataTable ref={dt} header={header} value={products} rowGroupMode="subheader" groupRowsBy="categoria" sortMode="single" sortField="categoria"
              sortOrder={1} rowGroupHeaderTemplate={headerTemplate} >
                <Column field="descricao" header="Descricao" style={{ minWidth: '200px' }}></Column>
                <Column field="quantidade" header="Quantidade" style={{ minWidth: '200px' }}></Column>
            </DataTable>
        </div>
    );
}
        