import { useEffect } from 'react';
import { connect } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import {ThemeProvider } from '@mui/material/styles'
import { checkCookie, fetchLoggedUser } from '../actions'
import theme from './theme'
import Root from './Root'
import auth from './auth';

const App = props => {
  const [cookies, setCookie, removeCookie] = useCookies('JSESSIONIDSSO')

  useEffect(() => {
        props.checkCookie(cookies)
        if(cookies && cookies.JSESSIONIDSSO) {
          props.fetchLoggedUser()
        }
  }, [checkCookie]);

  const logout = async () => {
    await props.logout()
    await removeCookie('JSESSIONIDSSO')
  }

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL} >
      <ThemeProvider theme={theme}>
          <Routes>
              <Route path="*" element={<Root auth={props.auth} />} />
          </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, { checkCookie, fetchLoggedUser })(App)