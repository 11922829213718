//import { useEffect } from 'react';
//import { connect } from 'react-redux';
import { Routes, Route } from "react-router-dom";
//import { fetchPedidos, fetchGruposClientes, fetchClientes } from '../../../actions';
//import Produto from './produto'
//import TipoEmbalagem from './tipo_embalagem'
import RelatorioEmbalagem from './relatorio/embalagem'

let Index = props => {

return <Routes>
        {/* 
        <Route path='/produto' element={<Produto />} />
        <Route path='/tipo_embalagem' element={<TipoEmbalagem/>}/>
        */}
        <Route path='/relatorio/embalagem' element={<RelatorioEmbalagem/>}/>
    </Routes>
}

/*
function mapStateToProps({ pedidos, gruposClientes, clientes }) {
  return { pedidos, gruposClientes, clientes };
}
  */

//Index = connect(mapStateToProps, { fetchPedidos, fetchGruposClientes, fetchClientes })(Index);

export default Index