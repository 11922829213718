import { connect } from 'react-redux';
/*
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import qs from 'qs'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import SelectMany from './selectMany'
import Calendar from './calendar'
import Table from './table'
*/
import Filter from './filter'
import Table2 from './table3'
import { fetchRelatorioEmbalagem } from '../../../../actions';




const Index = props => {

  const {relatorioEmbalagem, fetchRelatorioEmbalagem} = props



  return <>
    <Filter fetchRelatorioEmbalagem={fetchRelatorioEmbalagem} />
      {(relatorioEmbalagem.isLoading === false && !relatorioEmbalagem.error && !!relatorioEmbalagem.payload) && <Table2 relatorio={relatorioEmbalagem.payload} />}
  </>
}

const mapStateToProps = ({ relatorioEmbalagem }) => {
  return { relatorioEmbalagem };
}

export default connect(mapStateToProps, { fetchRelatorioEmbalagem })(Index);