import axios from 'axios';
import {
    FETCH_LOGGED_USER, FETCH_LOGGED_USER_SUCCESS, FETCH_LOGGED_USER_FAILURE,
} from './types';

export const fetchLoggedUser = () => async dispatch => {
    dispatch({ type: FETCH_LOGGED_USER});
    try {
        let res = await axios.get('/vaast-admin-web/api/user');
        dispatch({ type: FETCH_LOGGED_USER_SUCCESS, payload: { user: res.data } });
    } catch (err) {
        console.log(err);
        const { status, statusText } = err.response
        dispatch({ type: FETCH_LOGGED_USER_FAILURE, error: { status, statusText } });
    }
}