//import _ from 'lodash';
import axios from 'axios';
import formatISO from 'date-fns/formatISO'
//import moment from 'moment';
//import { SubmissionError } from 'redux-form'
import { FETCH_RELATORIO_EMBALAGEM, FETCH_RELATORIO_EMBALAGEM_SUCCESS, FETCH_RELATORIO_EMBALAGEM_FAILURE } from './types';
import { handleError } from './errorHandler';

const BASE_URL = process.env.PUBLIC_URL;

export const fetchRelatorioEmbalagem = params => async dispatch => {
    dispatch({ type: FETCH_RELATORIO_EMBALAGEM });
    const parsedParams = {...params}
    parsedParams.data = formatISO(params.data)
    try {
        const config = { params: parsedParams };
        const res = await axios.get(`${BASE_URL}/sig-ws/rest/producao/embalagem/relatorio`, config);
        dispatch({ type: FETCH_RELATORIO_EMBALAGEM_SUCCESS, payload: res.data });
    } catch (err) {
        handleError(dispatch, FETCH_RELATORIO_EMBALAGEM_FAILURE, err);
    }
}